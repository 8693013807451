
  export default {
    data() {
      return {
        modalVisible: false,
      };
    },
    props: {
      modal: {
        type: Object,
        required: true,
      },
    },
    mounted() {
      const el = document.getElementsByClassName('modal-trigger');

      for (let i = 0; i < el.length; i++) {
        el[i].addEventListener('click', () => (this.modalVisible = true));
      }
    },
  };
